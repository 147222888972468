import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerUnidadFiscalizable } from "../../../redux/actions/vigilancias/unidades_fiscalizables/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Guardar as GuardarFavorito } from "../favoritos/";
import { Tabs } from "./";

export class Detalle extends Component {
  componentDidMount = () => {
    const { obtenerUnidadFiscalizable, unidad_fiscalizable_id } = this.props;
    if (unidad_fiscalizable_id > 0) {
      obtenerUnidadFiscalizable({
        id: unidad_fiscalizable_id,
      });
    }
  };

  render = () => {
    const {
      is_loading_vigilancias,
      unidad_fiscalizable_id,
      // unidad_fiscalizable_componente,
      unidad_fiscalizable,
    } = this.props;

    if (unidad_fiscalizable_id === 0) {
      return <Navigate to="/compliance-analytics/unidades/listado" />;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Unidad Fiscalizable",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo la unidad fiscalizable, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_unidades" menu_2="listado" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-house-user"></i> UNIDADES FISCALIZABLES
                  </h4>
                  <p className="float-end btn-group">
                    <GuardarFavorito
                      tipo="unidad_fiscalizable"
                      id={unidad_fiscalizable.id}
                    />
                    <Link
                      className="btn btn-primary"
                      to="/compliance-analytics/unidades/listado"
                    >
                      <i className="fa fa-angle-left"></i> Volver al listado
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-body">
                    <Tabs unidades_fiscalizables={[unidad_fiscalizable]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Detalle.propTypes = {
  obtenerUnidadFiscalizable: PropTypes.func.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  unidad_fiscalizable: PropTypes.object.isRequired,
  unidad_fiscalizable_id: PropTypes.number.isRequired,
  unidad_fiscalizable_componente: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  unidad_fiscalizable: state.vigilancias.unidad_fiscalizable,
  unidad_fiscalizable_id: state.vigilancias.unidad_fiscalizable_id,
  unidad_fiscalizable_componente:
    state.vigilancias.unidad_fiscalizable_componente,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerUnidadFiscalizable }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalle);
