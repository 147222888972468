import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { obtenerExpediente } from "../../../redux/actions/vigilancias/expedientes/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Guardar as GuardarFavorito } from "../favoritos/";
import { Tabs } from "./";

export class Detalle extends Component {
  componentDidMount = () => {
    const { obtenerExpediente, expediente_id } = this.props;
    if (expediente_id > 0) {
      obtenerExpediente({
        id: expediente_id,
      });
    }
  };

  render = () => {
    const {
      is_loading_vigilancias,
      expediente_id,
      expediente,
      // expediente_componente,
    } = this.props;

    if (expediente_id === 0) {
      return <Navigate to="/compliance-analytics/expedientes/listado" />;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "SEIA",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los expedientes, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_expedientes" menu_2="listado" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-seedling"></i> EXPEDIENTES SEIA
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">{expediente.nombre}</h5>
                    <div className="float-end btn-group">
                      <GuardarFavorito tipo="expediente" id={expediente.id} />
                      <Link
                        className="btn btn-primary"
                        to="/compliance-analytics/expedientes/listado"
                      >
                        <i className="fa fa-angle-left"></i> Volver al listado
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <Tabs expedientes={[expediente]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Detalle.propTypes = {
  obtenerExpediente: PropTypes.func.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  expediente: PropTypes.object.isRequired,
  expediente_id: PropTypes.number.isRequired,
  expediente_componente: PropTypes.string,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  expediente: state.vigilancias.expediente,
  expediente_id: state.vigilancias.expediente_id,
  expediente_componente: state.vigilancias.expediente_componente,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerExpediente }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalle);
